import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import ResponsiveImage from '../Image'
const AuthorInfo = ({ id }) => {
    const { strapidata } = useStaticQuery(
        graphql`
          query {
            strapidata {
                authors {
                data {
                    id
                    attributes {
                    name
                    shortbio
                    avatar {
                        data {
                        attributes {
                            width
                            url
                            height
                            formats
                        }
                        }
                    }
                    }
                }
                }
            }                    
          }
        `
      )

    const Authors = strapidata.authors.data

    const authorInfo = Authors.find((item)=>{
        return item.id===id
    })

    return (
        <div className='flex sm:flex-row flex-col items-center w-full sm:gap-10 gap-0 mt-4 blogsingle-author'>
            {authorInfo?.attributes?.avatar?.data?.attributes?.url && (
                <ResponsiveImage 
                        large={authorInfo.attributes.avatar.data.attributes.url}
                    medium={authorInfo.attributes.avatar.data.attributes.url}
                        small={authorInfo.attributes.avatar.data.attributes.url}
                        width={authorInfo.attributes.avatar.data.attributes.width}
                    height={authorInfo.attributes.avatar.data.attributes.height}
                    alt={authorInfo && authorInfo.attributes.name && authorInfo.attributes.name}
                />
            )}
            {/* <StaticImage src="../../assets/images/blogsingle-author.png" alt="" /> */}
            <div className='flex flex-col sm:text-left text-center w-100'>
                <h6>author</h6>
                <h5>{authorInfo && authorInfo.attributes.name && authorInfo.attributes.name}</h5>
                <span className="text-base">{authorInfo && authorInfo.attributes.shortbio && authorInfo.attributes.shortbio}</span>
            </div>
        </div>
    )
}

export default AuthorInfo