import * as React from "react"
import { graphql } from 'gatsby'
import Page from './../components/Page'
import Blog from "../components/Blogs";

import Seo from "../components/Seo";

const BlogTemplate = (props) => {
  const {data} = props
  const post = data?.strapidata?.articles?.data ? data.strapidata.articles.data[0] : {}
  const seo = data?.strapidata?.articles?.data ? data.strapidata.articles.data[0].attributes.seo : {}
  if (!post) return null;
  return (
    <Page page="blog">
      <Seo
        title={seo && seo.metaTitle && seo.metaTitle}
        description={seo && seo.metaDescription && seo.metaDescription}
        pimage={seo && seo.shareImage.data.attributes.url && seo.shareImage.data.attributes.url}
        cpath = {props.location.pathname}
        />
      <Blog data={post.attributes}/>
    </Page>
  );
};

export default BlogTemplate;

export const query = graphql`
  query BlogBySlug($slug: String!) {
    strapidata {
        articles(filters: {slug: {eq: $slug}}) {
          data {
            attributes {
              seo {
                metaTitle
                metaDescription
                shareImage {
                  data {
                    attributes {
                      url
                    }
                  }
                }
              }
              title
              slug
              readTimeInMinutes
              publishedAt
              content
              categories {
                data {
                  attributes {
                    slug
                    name
                  }
                }
              }
              cover {
                data {
                  attributes {
                    url
                    formats
                  }
                }
              }
              author {
                data {
                  id
                }
              }
            }
          }
        }
      }
  }
`;