import * as React from "react"
import ReactMarkdown from "react-markdown";
import moment from 'moment';
import ResponsiveImage from '../Image'
import AuthorInfo from "./AuthorInfo";


const Blog = ({data}) => {
    const { title, cover, publishedAt, readTimeInMinutes, categories, content, author } = data
    const featuredImage = cover?.data?.attributes?.url ? cover.data.attributes : ''
    return (
         <div className="relative">
                    {featuredImage && (
                        <ResponsiveImage 
                            large={featuredImage.url}
                        medium={featuredImage.formats.thumbnail.url}
                            small={featuredImage.formats.thumbnail.url}
                            width={featuredImage.width}
                        height={featuredImage.height}
                        className="w-full md:block hidden"
                        alt={title && title}
                />
                    )}
                    <div className="max-w-7xl mx-auto px-4 py-20">
                        <div className="w-full grid md:grid-cols-4 grid-cols-1 gap-10">
                            <div className="sm:w-2/4 w-full">
                                { categories && categories.data && categories.data.map((cat, index)=>(
                                    <h2 key={index} className="text-2xl w-full mb-4">{cat.attributes.name}</h2>
                                ))}
                                
                                <div className="flex flex-row justify-between mt-10 w-full">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="28" viewBox="100.001 529 32 28"><path d="M102.855 540.324a5.963 5.963 0 0 1-1.854-4.328c0-3.31 2.696-5.996 6.023-5.996a6.023 6.023 0 0 1 5.593 3.768 6.032 6.032 0 0 1 5.597-3.768c3.327 0 6.025 2.686 6.025 5.996 0 2.016-1 3.799-2.533 4.886L112.653 550l-9.798-9.676Z" strokeLinejoin="round" strokeLinecap="round" strokeWidth="2" stroke="#3c4fe0" fill="transparent" data-name="Union 6" /></svg>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="28" viewBox="204 529 32 28"><path d="M207.926 545.845H205V530h21.498v15.845h-8.878L206.501 551l1.425-5.155Z" strokeLinejoin="round" strokeLinecap="round" strokeWidth="2" stroke="#3c4fe0" fill="transparent" data-name="Union 5" /></svg>
                                </div>
                            </div>
                            <div className="col-span-3">
                                <div className="page-content w-full">
                                    <div className="lg:w-3/4 w-full">
                                        <h1>{title && title}</h1>
                                        <div className="flex flex-row gap-10 items-center mb-4">
                                            <p className="text-gray-400 text-sm flex items-center mr-3"><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                                            </svg> -{moment(publishedAt).format('Do MMM')}</p>
                                            <p className="text-gray-400 text-sm flex items-center"><svg xmlns="http://www.w3.org/2000/svg" className="h-7 w-6 mr-2" viewBox="332 487.95 24 28"><g data-name="Group 245"><path d="M340.097 491.448v6.785h4.777" strokeLinejoin="round" strokeLinecap="round" strokeWidth="2" stroke="#bbb" fill="transparent" data-name="Path 193" /><path d="M332 497.405a9.5 9.5 0 1 1 0 .09z" strokeLinejoin="round" strokeLinecap="round" strokeWidth="1.80952" stroke="#bbb" fill="transparent" data-name="Ellipse 57" /></g></svg> -{readTimeInMinutes} min read</p>
                                        </div>
                                        <ReactMarkdown>{content}</ReactMarkdown>
                                        <AuthorInfo id={author?.data?.id}/>                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
    )

}

export default Blog